<template>
  <div id="app" ref="app" style="height: 100vh; overflow-y: auto">
    <div>
      <div class="top">
    <div class="bcg_top">

    </div>
     <div class="bcg_top">
      
    </div>
     <div class="bcg_top">
      
    </div>
     <div class="bcg_top">
      
    </div>
     <div class="bcg_top">
      
    </div>

        <div class="titl">
          <div class="big">物鱼</div>
          <ul>
            <li style="color: #77c3bb">首页</li>
            <li @click="likTo(1)">物鱼服务</li>
            <li @click="likTo(2)">物鱼热卖</li>
            <li @click="likTo(3)">关于我们</li>
            <li @click="likTo(4)">联系我们</li>
          </ul>
        </div>

        <div
          style="display: flex; justify-content: center; padding-bottom: 96px"
        >
          <img class="top_phoe" src="./assets/XS-SKY.png" alt="" />
          <div class="phoe_right">
            <p>物鱼</p>
            <span>奢品圈找货神器</span>
            <div style="display: flex">
              <div class="small">
                <img
                  style="width:18px;height:21px;margi-right;10px"
                  src="./assets/ai-ios.png"
                  alt=""
                />
                <p>ios版</p>
              </div>
              <div class="small" style="margin-left: 31px">
                <img
                  style="width:18px;height:21px;margi-right;10px"
                  src="./assets/android-fill.png"
                  alt=""
                />
                <p>Adroid版</p>
              </div>
            </div>
            <div style="margin-top: 160px; color: #fff; font-size: 12px">
              版本1.0
            </div>
          </div>
        </div>
        <div class="top_next">
          <p>正品保障</p>
          <div></div>
          <p>权威鉴定</p>
          <div></div>
          <p>交易放心</p>
          <div></div>
          <p>售后无忧</p>
        </div>
      </div>

      <!-- 服务 -->
      <div class="serve">
        <p class="til_ser">全链路服务</p>
        <p>
          拥有专业名表鉴定团队，提供线上下名表鉴定服务，国家认可、安全可靠，线上一键下单，支持邮寄维修，专业完善售后体系。
        </p>
        <div class="serve_img">
          <div class="serve_hover">
            <img src="./assets/VCG41N944559020.png" alt="" />
            <div class="cover">
              <p>鉴定</p>
              <span>
                深度检测 严苛流程，30+项检测环节
                拥有专业名表鉴定团队，提供线上下
                名表鉴定服务，国家认可、安全可靠
              </span>
              <div>立即体验</div>
            </div>
          </div>
          <div class="serve_hover">
            <img src="./assets/posi(2).png" alt="" />

            <div class="cover">
              <p>维修</p>
              <span>
                全国100+特约维修中心，线上一键下单，专业完善售后体系。先修后付，报价透明，平台全程审核监控
              </span>
              <div>立即体验</div>
            </div>
          </div>
          <div class="serve_hover">
            <img src="./assets/posi(4).png" alt="" />

            <div class="cover">
              <p>回收</p>
              <span>
                物鱼官方直收，精准估价
                即时打款线上一键下单，支持邮寄手表回收，全链路监控，高价、高效、省心
              </span>
              <div>立即体验</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 热卖 -->
      <div class="hot_serve">
        <p class="til_ser">物鱼热卖</p>
        <p>选择物鱼平台，正品保障，国家认可，交易放心，售后无烦恼</p>
        <div class="serve_img" style="margin: 0 8%">
          <div class="hot_hover">
            <img class="hot_img" src="./assets/posi.png" alt="" />

            <div class="cover2">
              <span style="width: 135px">
                江诗丹顿纵横四海系列 自动机械男士腕表
              </span>
              <div>￥219,000.00</div>
              <div>￥248,000.00</div>
            </div>
          </div>
          <div class="hot_hover">
            <img class="hot_img" src="./assets/posi(1).png" alt="" />

            <div class="cover2">
              <span style="width: 108px"> 浪琴典藏系列 自动机械女士腕表 </span>
              <div>￥8,700.00</div>
              <div>￥15,700.00</div>
            </div>
          </div>
          <div class="hot_hover">
            <img
              class="hot_img"
              src="./assets/watch.png.transform.appdpmain.png"
              alt=""
            />

            <div class="cover2">
              <span style="width: 129px">
                爱彼CODE 11.59系列 自动机械男士腕表
              </span>
              <div>￥256,000.00</div>
              <div>￥355,000.00</div>
            </div>
          </div>
          <div class="hot_hover">
            <img
              class="hot_img"
              src="./assets/watch.png.transform.appdpmain(1).png"
              alt=""
            />

            <div class="cover2">
              <span style="width: 108px">
                爱彼皇家橡树系列 自动机械女士腕表
              </span>
              <div>￥8,700.00</div>
              <div>￥426,000.00</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 关于 -->
      <div class="hot_serve" style="background: #f9f9f9">
        <p class="til_ser">关于物鱼</p>
        <p style="width: 800px; text-align: left">
          物鱼脱离于母公司稀蜴，稀蜴 (SeeEase Watch)
          是一个提供全链路服务的二手品牌腕表交易平台，目前已与100余家独立腕表零售商达成战略合作，并具有核心旗舰店、城市合伙人联营店和实体售后网点体系。平台服务类目覆盖全球中高端品牌腕表网上交易、旗舰店实体体验、腕表鉴定、腕表估价、腕表回收、腕表维修六大类目。
        </p>
        <div class="serve_img" style="margin: 0 8%; padding-bottom: 108px">
          <div class="about_hover">
            <img class="hot_img" src="./assets/gyxy.png" alt="" />

            <div>多品牌核心旗舰店</div>
          </div>
          <div class="about_hover">
            <img
              class="hot_img"
              src="./assets/66756fdb4050b5695e00841fd3227854.png"
              alt=""
            />

            <div>百余家合作腕表零售商</div>
          </div>
          <div class="about_hover">
            <img class="hot_img" src="./assets/VCG41549595857.png" alt="" />
            <div>城市合伙人联营店</div>
          </div>
          <div class="about_hover">
            <img class="hot_img" src="./assets/item03.png" alt="" />
            <div>实体售后网点体系</div>
          </div>
        </div>
      </div>

      <div class="touch">
        <p class="titl" style="padding: 66px">联系我们</p>

        <div class="about">
          <img src="./assets/2021-07-02 11.14.24.png" alt="" />
          <div class="about_msg">
            <p>杭州物鱼网络科技有限公司</p>
            <p>电话： 0571-85805537</p>
            <p>地址：浙江省杭州市滨江区滨兴路 1866号</p>
            <p>阿里中心滨江园区3 号楼 805</p>
          </div>
        </div>
      </div>
      <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
      <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->

      <div class="last">
        <div class="last_l">
          <p @click="likTo(0)">首页</p>

          <p @click="likTo(1)">物鱼服务</p>
          <p @click="likTo(2)">物鱼热卖</p>
          <p @click="likTo(3)">关于我们</p>
          <p @click="likTo(4)">联系我们</p>
        </div>
        <div class="last_c">
          杭州物鱼网络科技有限公司 电话： 0571-85805537
          地址：浙江省杭州市滨江区滨兴路 1866号阿里中心滨江园区3号楼805
        </div>
        <div class="last_b">浙ICP备2021016489号-1</div>
      </div>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: "App",
  methods: {
    likTo(i) {
      if (i == 0) {
        this.$refs.app.scrollTop = 0;
      }
      if (i == 1) {
        let that = this;
        // var nowTime = new Date().getTime();
        // var timestamp;
        // function callbackfy() {
        //   let nowTimes = new Date().getTime();
        //   timestamp = nowTimes - nowTime;
        //   that.$refs.app.scrollTop = 750 * (timestamp /300);
        //   if (timestamp <= 300) {
        //     // 在两秒后停止动画
        //     window.requestAnimationFrame(callbackfy);
        //   }
        // }
        // window.requestAnimationFrame(callbackfy);
        var i =0
        let time = setInterval(()=>{
          ++i;
          that.$refs.app.scrollTop = 750 * (i/10);
          if(i==10){
           clearInterval(time)
          }
        },20)
      }
      if (i == 2) {
          let that = this;
        // var nowTime = new Date().getTime();
        // var timestamp;
        // function callbackfy() {
        //   let nowTimes = new Date().getTime();
        //   timestamp = nowTimes - nowTime;
        //   that.$refs.app.scrollTop = 1400 * (timestamp / 300);
        //   if (timestamp < 300) {
        //     // 在两秒后停止动画
        //     window.requestAnimationFrame(callbackfy);
        //   }
        // }
        // window.requestAnimationFrame(callbackfy);
         var i =0
        let time = setInterval(()=>{
          ++i;
          that.$refs.app.scrollTop = 1400 * (i/10);
          if(i==10){
           clearInterval(time)
          }
        },20)
      }
      if (i == 3) {
          let that = this;
        // var nowTime = new Date().getTime();
        // var timestamp;
        // function callbackfy() {
        //   let nowTimes = new Date().getTime();
        //   timestamp = nowTimes - nowTime;
        //   that.$refs.app.scrollTop = 2020 * (timestamp / 300);
        //   if (timestamp < 300) { 
           
        //     // 在两秒后停止动画
        //     window.requestAnimationFrame(callbackfy);
        //   }
        // }
        // window.requestAnimationFrame(callbackfy);
           var i =0
        let time = setInterval(()=>{
          ++i;
          that.$refs.app.scrollTop = 2020 * (i/10);
          if(i==10){
           clearInterval(time)
          }
        },20)
      }
      if (i == 4) {
         let that = this;
        // var nowTime = new Date().getTime();
        // var timestamp;
        // function callbackfy() {
        //   let nowTimes = new Date().getTime();
        //   timestamp = nowTimes - nowTime;
        //   that.$refs.app.scrollTop = 2800 * (timestamp / 300);
        //   if (timestamp < 300) {
        //     // 在两秒后停止动画
        //     window.requestAnimationFrame(callbackfy);
        //   }
        // }
        // window.requestAnimationFrame(callbackfy);
           var i =0
        let time = setInterval(()=>{
          ++i;
          that.$refs.app.scrollTop = 2800 * (i/10);
          if(i==10){
           clearInterval(time)
          }
        },20)
      }
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.top {
  background: rgba(0, 0, 0, 0.6);
  position: relative;
}
.top .bcg_top{
   position: absolute;
   background: url('./assets/VCG41N755765853 (1).png');

   width: 20%;
   background-size: cover;
   background-repeat: no-repeat;
   height: 733px;
   z-index: -1;
    opacity: .8;
    filter: blur(12px);
}
.top .bcg_top:hover{
  filter: blur(0px);
}
.top .bcg_top:nth-child(0){
     left: 0;
}
.top .bcg_top:nth-child(1){
  background: url('./assets/VCG41N755765853备份 (1).png');
  
     left: 20%;
}.top .bcg_top:nth-child(2){
   background: url('./assets/VCG41N755765853备份 2 (1).png');
     left: 40%;
}.top .bcg_top:nth-child(3){
   background: url('./assets/VCG41N755765853备份 3 (1).png');
     left: 60%;
}
.top .bcg_top:nth-child(4){
   background: url('./assets/VCG41N755765853备份 4 (1).png');
     left: 80%;
}
.titl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 5%;
  color: #fff;
}
.titl .big {
  font-size: 23px;
}
.titl ul {
  width: 55%;
  display: flex;
  justify-content: space-around;
}
.titl ul li {
  cursor: pointer;
}
.titl ul li:hover {
  color: #82bdb6;
}
.titl li {
  font-size: 13px;
  /* color: #fff; */
  list-style: none;

  font-family: MicrosoftYaHei;
}
.top_phoe {
  width: 255px;
  height: 510px;
}
.phoe_right {
  margin-left: 80px;
}
.phoe_right p {
  height: 66px;
  font-size: 50px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 66px;
  margin-top: 87px;
}
.phoe_right span {
  font-size: 44px;
  margin-top: 18px;
  margin-bottom: 66px;
  display: block;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 58px;
  letter-spacing: 2px;
}
.phoe_right .small {
  display: flex;
  align-items: center;
  background: #65bab1;
  cursor: pointer;
  border-radius: 30px;
  padding: 5px 10px;
}
.phoe_right .small p {
  height: 24px;
  font-size: 18px;

  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  margin: 0;
  margin: 0 6px;
}
.top_next {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  justify-content: space-around;
  margin: 0 13%;
  padding-bottom: 44px;
}
.top_next div {
  width: 1px;
  height: 20px;
  background: #ffffff;
}
.serve{
  background: #F9F9F9;
}
.serve p {
  text-align: center;
}
.serve .til_ser {
  font-size: 26px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #060606;
  /* line-height: 36px; */
  padding-top: 73px;
  margin-bottom: 29px;
}
.serve p:nth-child(2) {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  margin: auto;
  font-weight: 400;
  margin-bottom: 44px;
  color: #333333;
  width: 448px;
  line-height: 22px;
}
.serve_img {
  height: 267px;
  overflow: hidden;
  color: #fff;
}
.serve_img .cover {
  background: #3fa89b;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  transition: all 0.3s;
  opacity: 0.88;
  cursor: pointer;
  height: 0;
}
.cover p {
  font-size: 19px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-top: 44px;
  margin-bottom: 22px;
  line-height: 27px;
}
.cover span {
  width: 210px;
  display: block;
  margin: auto;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
}
.cover div {
  margin: auto;
  padding: 4px 6px;
  margin-top: 44px;
  display: inline-block;
  display: flex;
  width: 90px;
  justify-content: center;
  height: 26px;
  border-radius: 17px;
  border: 1px solid #ffffff;
}
.serve_img {
  padding-bottom: 156px;
}
.serve_img .serve_hover {
  position: relative;
  overflow: hidden;
}
.serve_img .serve_hover:hover .cover {
  height: 267px;
  z-index: 10;
}
.serve_img {
  display: flex;
  justify-content: center;
}
.serve_img img {
  width: 287px;
  height: auto;
}
.hot_serve {
  background: #fff;
}
.hot_serve p {
  text-align: center;
}
.hot_serve .til_ser {
  font-size: 26px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #060606;
  /* line-height: 36px; */
  padding-top: 73px;
  margin-bottom: 29px;
}
.hot_serve p:nth-child(2) {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  margin: auto;
  font-weight: 400;
  margin-bottom: 44px;
  color: #333333;
  width: 448px;
  line-height: 22px;
}
.hot_hover {
  display: flex;
  flex-direction: column;
     min-width: 226px
}
.hot_hover img {
  width: auto;
  height: 226px;
  padding: 0 12px;
}
.hot_img {
  padding: 0 12px;
  background: #fff;
  height: 226px;
  object-fit: contain;
  margin: 0 30px;
  max-width: 226px;
}
.cover2 span {
  display: block;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  text-align: center;
  margin: auto;
  margin-top: 29px;
}
.cover2 div {
  text-align: center;
}
.cover2 div:nth-child(2) {
  color: #b70707;

  font-size: 15px;
  margin-top: 18px;
}
.cover2 div:nth-child(3) {
  text-decoration: line-through;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #717171;
}
.about_hover {
  width: 172px;
  height: 277px;
  position: relative;
}
.about_hover div {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 13px;
  opacity: 0.6;
  color: #fff;
  padding: 7px 0;
  background: #000;
  width: 100%;
  text-align: center;
  white-space: nowrap;
}
.about_hover img {
  width: 172px;
  padding: 0;
  margin: 0;
  height: 277px;
}
.touch {
  margin-top: 28px;
  background: #fff;
  padding-bottom: 88px;
}
.touch .titl {
  font-size: 26px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #060606;
  text-align: center;
  justify-content: center;
  display: flex;
}
.touch .about_msg {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #fcfcfc;
  padding: 58px;
  font-size: 12px;
}
.touch .about {
  display: flex;
  justify-content: center;
}
.touch .about img {
  width: 455px;
  height: 277px;
}
.last {
  height: 195px;
  background: #1d1d1d;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-around;
}
.last .last_l {
  display: flex;
  justify-content: space-around;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
}
.last .last_l p {
  cursor: pointer;
}
.last_c,
.last_b {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  text-align: center;
}
</style>
